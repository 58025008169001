<template>
    <modal ref="modalGestionarFaqs" :titulo="tituloModal" icon="instructions">
        <div class="row m-3 f-15">
            <div class="col">
                <label class="ml-2" v-text="`Termino`" />
                <el-input v-model="titulo" maxlength="80" show-word-limit class="pr-input" />
            </div>
        </div>
        <div class="row m-3 f-15">
            <div class="col">
                <label class="ml-2" v-text="`Explicacion`" />
                <el-input v-model="texto" type="textarea" :rows="3" maxlength="1000" show-word-limit class="pr-input" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ModalGestionarFaqs',
    props: {
        tipo: {
            type: Number,
            default: 1
        },
        modoEdicion: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            texto: '', 
            titulo: '', 
        }
    },
    computed: {
        tituloModal(){
            return this.modoEdicion ? `Editar Termino` : `Crear Termino`
        }
    },
    methods: {
        toggle(){
            this.$refs.modalGestionarFaqs.toggle()
        },
    }
}
</script>
